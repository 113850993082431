export const apps = [
  {
    path: "tictactoe",
    name: "Tic Tac Toe",
    description: `Simple Tic Tac Toe implementation that has allowed me to test
    out css grid / flexbox while creating something with solid-js.`
  }
];
/*
, {
    path: "colorsays",
    name: "Color Says",
    description: `Simple Color Memory game that has allowed me to play around with css grid and solid effects library.`
  }
*/
